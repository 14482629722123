<template>
  <div class="FontSettingsMain">
    <div>
      <div  style="background: #F7F7F7;  cursor: pointer; border-bottom: 1px solid #D2D8E0; border-top: 1px solid #D2D8E0;" class=" mb-3 button-editor-heading px-3 py-2 d-flex justify-content-between align-items-center">
          <div style="color: #18191C; font-weight: 500; font-size: 16px" class="">Font</div>
          <div class="button-chevron" style="color: #B3AFB6; font-weight: 500; font-size: 16px" >
        </div>
        </div>
      <i class="text-danger" style="font-size: 13px;">
        * Selected font will be used for every quiz page
      </i>
    </div>
<div class="">
  <FontFamily v-model="selectedFont"></FontFamily>
</div>
  </div>
</template>
<script>
import fontFamilyList from "../../../staticPages/fontFamilyList";

import { mapGetters } from "vuex";
import FontFamily from "../../customize/MiniComponents/FontFamily.vue";
export default {
  components:{
    FontFamily
  },
  data() {
    return {
      isFontUpdatedLoading: false,
      selectedFont:null
    };
  },
  watch:{
    selectedFont(newValue){
      this.handleFontChange(newValue)
    },
  },
  methods: {
    async handleFontChange(e) {
      let selectedFont = e;

      this.$store.dispatch('updateFontFamily',{oldFontFamily:null,newFontFamily:selectedFont})

      
      const allPages = [...this.getAllPages];

      try {
        this.isFontUpdatedLoading = true;

        allPages.forEach((item) => {
          if (item.value == "resultPage") {
            item.pageDesign.NoResultBlocksArray.forEach((element) => {
              switch (element.type) {
                case "text":
                  this.$set(element.style, "fontFamily", selectedFont);
                  break;
                case "button":
                  if (typeof element.content != "object") {
                    this.$set(element.style, "fontFamily", selectedFont);
                  } else {
                    this.$set(
                      element.content.backButton.style,
                      "fontFamily",
                      selectedFont
                    );
                    this.$set(
                      element.content.nextButton.style,
                      "fontFamily",
                      selectedFont
                    );
                  }
                  break;
              }
            });
          }
          item.pageDesign.blocksArray.forEach((element) => {
            switch (element.type) {
              case "text":
                this.$set(element.style, "fontFamily", selectedFont);
                break;
              case "button":
                if (typeof element.content != "object") {
                  this.$set(element.style, "fontFamily", selectedFont);
                } else {
                  this.$set(
                    element.content.backButton.style,
                    "fontFamily",
                    selectedFont
                  );
                  this.$set(
                    element.content.nextButton.style,
                    "fontFamily",
                    selectedFont
                  );
                }
                break;
              case "option":
                this.$set(element.style, "fontFamily", selectedFont);
                break;
              case "freeText":
                this.$set(element.style, "fontFamily", selectedFont);
                this.$set(element.style, "DateFontFamily", selectedFont);
                break;
              case "checkbox":
                this.$set(element.style, "fontFamily", selectedFont);
                break;
              case "product":
                this.$set(
                  element.style,
                  "bulletDescriptionfontFamily",
                  selectedFont
                );
                this.$set(element.style, "detailListFontFamily", selectedFont);
                this.$set(element.style, "fontFamily", selectedFont);
                this.$set(
                  element.style.productVariantStyles,
                  "fontFamily",
                  selectedFont
                );
                break;
            }
          });
        });

        this.$set(
          this.getProgressBar.progressStyles,
          "fontFamily",
          selectedFont
        );

        this.$nextTick(() => {
          this.$toasted.show("Font successfully applied", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
        });
      } catch (error) {
        console.log("Error Occurred", error);
        this.$toasted.show("Error Occurred while using Scheme", {
          theme: "toasted-primary",
          position: "bottom-center",
          duration: 2000,
        });
      } finally {
        this.isFontUpdatedLoading = false;
      }
    },
  },
  computed: {
    ...mapGetters(["getTotalPages", "getProgressBar"]),
    getFontFamilyList() {
      return fontFamilyList;
    },
    getAllPages() {
      return this.getTotalPages;
    },
  },
};
</script>
